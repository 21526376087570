<template>
    <div>
        <div class="row my-4 filters">
            <div class="col-md-12">
                <form class="form-inline float-right">
                    <div class="mx-3">
                        <button class="btn btn-dark" @click="resetFilter">Filter löschen</button>
                    </div>
                    <div class="form-group mx-3">
                        <label class="pr-2 pl-2 font-weight-bold" for="filterFromDate"> Erstelldatum von:</label>
                        <input type="date" id="filterFromDate" class="form-control"
                               v-model="filter.startDate"/>

                        <label class="pr-2 pl-2 font-weight-bold" for="filterUntilDate"> bis:</label>
                        <input type="date" id="filterUntilDate" class="form-control"
                               v-model="filter.endDate"/>

                        <label class="pr-2 pl-5 font-weight-bold" for="filterName"> Suchbegriff:</label>
                        <input
                            id="filterName"
                            type="text"
                            class="form-control"
                            placeholder="Suche..."
                            v-model="filter.name"
                        >
                        <button @click.prevent="updateFilter" class="btn btn-primary btn-dark">Suche</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            filter: this.$store.state.emailTemplate.filter
        }
    },
    methods: {
        commitFilterParams() {
            this.$store.commit('emailTemplate/setFilter', this.filter)
        },
        updateFilter() {
            this.$store.commit('emailTemplate/setCurrentPage', 1)
            this.commitFilterParams();
            this.$emit('filter-changed');
        },
        resetFilter() {
            console.log('resetFilter');
            this.filter = {
                name: '',
                startDate: '',
                endDate: '',
            };
            // this is not working all the time, sometimes the store is set to the state from before, whatever that was
            // Hä??
            // this.$store.dispatch('emailTemplate/reset');
            this.$store.commit('emailTemplate/setFilter', this.filter)
            this.$store.commit('emailTemplate/setCurrentPage', 1)
            this.$store.commit('emailTemplate/setSort', {
                field: 'template_id',
                desc: true,
            })
            this.$emit('filter-changed');
            // console.log('filter searchbar: ' + this.$store.state.emailTemplate.filter.name)
        },
    }
}
</script>
