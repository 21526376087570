<template>
    <div class="container-fluid">
        <div class="position-absolute" style="z-index: 100">
            <b-button variant="primary" @click="$bvModal.show('create-new-modal')">Erstellen</b-button>
        </div>

        <search-bar @search="fetchData" :show-info="false" :show-filter="false"></search-bar>

        <b-table hover :items="poolProxys" :fields="tableFields" custom-foot>
            <template v-slot:cell(pools)="data">
                <div class="row">
                    <div class="col-2">
                        <ul class="my-0">
                            <li v-for="pool in data.item.pools">{{ pool.key }}</li>
                        </ul>
                    </div>
                    <div class="col-4">
                        <ul class="my-0">
                            <li v-for="pool in data.item.pools">{{ pool.name}}</li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <b-button
                            variant="primary"
                            @click="activePoolProxy = data.item.key; $bvModal.show('add-pool-modal')"
                        >
                            <b-icon-plus></b-icon-plus>Neuen Daten-Pool hinzufügen
                        </b-button>
                    </div>
                </div>
            </template>

            <template v-slot:cell(edit)="data">
                <b-button-group>
                    <router-link :to="{ name: 'pool_proxy_settings', params: { poolProxyKey: data.value }}">
                        <b-icon-pencil font-scale="2"></b-icon-pencil>
                    </router-link>
                    <router-link :to="{ name: 'pool_proxy_dataflow', params: { poolProxyKey: data.value }}">
                        <b-icon-diagram-2 font-scale="2"></b-icon-diagram-2>
                    </router-link>
                    <b-link @click.prevent="downloadSwagger(data.value)">
                        <b-icon-braces font-scale="2"></b-icon-braces>
                    </b-link>
                </b-button-group>
            </template>

            <template v-slot:custom-foot>
                <tr>
                    <td colspan="4">
                        <strong>Anzahl: {{ totalNumberOfPoolProxys }}</strong>
                    </td>
                </tr>
            </template>
        </b-table>

        <pool-proxy-paginator
            @currentPageWasChanged="fetchData($event)"
            :items="poolProxys"
            :totalNumberOfItems="totalNumberOfPoolProxys"
            :limitPerPage="limitPerPage"
            :reset="resetPaginator"
        ></pool-proxy-paginator>

        <b-modal
            id="create-new-modal"
            ref="create-new-modal"
            title="Initialen Daten-Pool erzeugen"
            cancel-title="Abbrechen"
            ok-title="Neuen Pool-Proxy erstellen"
            @ok="createNewPoolProxy"
            @show="fetchExportRules"
        >
            <b-form-group label="Export-Regel">
                <b-form-select
                    v-model="selectedExportRule"
                    :options="exportRules"
                    @change="showExportRuleErrorMsg=false"
                ></b-form-select>
                <b-alert
                    class="mt-2"
                    v-model="showExportRuleErrorMsg"
                    variant="danger"
                >Wähle bitte eine Export-Regel aus.</b-alert>
            </b-form-group>
            <b-form-group label="Pool-Name">
                <b-form-input v-model="newTitle" @input="showCreateErrorMsg=false"></b-form-input>
                <b-alert class="mt-2" v-model="showCreateErrorMsg" variant="danger">{{ createErrorMsg }}</b-alert>
            </b-form-group>
        </b-modal>

        <b-modal
            id="add-pool-modal"
            ref="add-pool-modal"
            title="Neuen Daten-Pool hinzufügen"
            cancel-title="Abbrechen"
            ok-title="Daten-Pool hinzufügen"
            @ok="addPool"
            @show="fetchExportRules"
        >
            <b-form-group label="Export-Regel">
                <b-form-select
                    v-model="selectedExportRule"
                    :options="exportRules"
                    @change="showExportRuleErrorMsg=false"
                ></b-form-select>
                <b-alert
                    class="mt-2"
                    v-model="showExportRuleErrorMsg"
                    variant="danger"
                >Wähle bitte eine Export-Regel aus.</b-alert>
            </b-form-group>
            <b-form-group label="Pool-Name">
                <b-form-input v-model="newTitle" @input="showCreateErrorMsg=false"></b-form-input>
                <b-alert class="mt-2" v-model="showCreateErrorMsg" variant="danger">{{ createErrorMsg }}</b-alert>
            </b-form-group>
        </b-modal>
    </div>
</template>

<script>
import { BIcon, BIconPlus, BIconPencil, BIconDiagram2, BIconBraces } from "bootstrap-vue";
import Paginator from "./Paginator.vue";
import SearchBar from "./SearchBarPoolProxy.vue";
import FileSaver from "file-saver";
export default {
    components: {
        BIcon,
        BIconPlus,
        BIconPencil,
        BIconDiagram2,
        BIconBraces,
        FileSaver,
        poolProxyPaginator: Paginator,
        searchBar: SearchBar,
    },
    data() {
        return {
            tableFields: [
                {
                    key: "key",
                    label: "Key",
                },
                {
                    key: "party.name",
                    label: "Ersteller",
                },
                {
                    key: "pools",
                    label: "Daten-Pool(s)",
                },
                {
                    key: "finisherCount",
                    label: "Finisher"
                },
                {
                    key: "edit",
                    label: "",
                },
            ],
            limitPerPage: 15,
            poolProxys: [],
            totalNumberOfPoolProxys: 0,
            resetPaginator: false,
            exportRules: [],
            selectedExportRule: null,
            newTitle: "",
            showCreateErrorMsg: false,
            showExportRuleErrorMsg: false,
            createErrorMsg: "",
            activePoolProxy: null,
        };
    },
    created() {
        this.fetchData(1);
    },
    methods: {
        updateTable(data) {
            const poolProxysArray = [];
            for (let i in data.poolProxys) {
                let poolProxy = data.poolProxys[i];

                poolProxy["finisherCount"] = poolProxy.finisher_settings.length;
                poolProxy["edit"] = poolProxy.key;

                poolProxysArray.push(poolProxy);
            }
            this.poolProxys = poolProxysArray;
            this.totalNumberOfPoolProxys = data.total;
        },
        fetchData(currentPage, resetPaginator) {
            if (resetPaginator) {
                this.resetPaginator = resetPaginator;
            }

            let params = {
                limit: this.limitPerPage,
                offset: (currentPage - 1) * this.limitPerPage,
            };

            let searchValue = this.$store.state.poolProxy.filter.searchValue;
            if (searchValue) {
                params.searchValue = searchValue;
            }

            let party = this.$store.state.party;
            if (party) {
                params.party = party;
            }

            this.$http
                .get("interface/poolproxy", {
                    params: params,
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/interface/poolproxy"
                        ),
                    },
                })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    this.updateTable(data);
                });
        },
        fetchExportRules() {
            this.$http
                .get("export_rule", {
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/export_rule"
                        ),
                    },
                })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    this.exportRules = [];
                    for (let i in data) {
                        this.exportRules.push(data[i]);
                    }
                });
        },
        createNewPoolProxy(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.showCreateErrorMsg = false;

            if (!this.selectedExportRule) {
                this.showExportRuleErrorMsg = true;
                return false;
            }

            if (this.newTitle.length < 3) {
                this.showCreateErrorMsg = true;
                this.createErrorMsg = "Mindestens 3 Zeichen eingeben.";
                return false;
            }

            this.$http
                .post(
                    "proxy",
                    {
                        pools: [
                            {
                                name: this.newTitle,
                                export_rule: this.selectedExportRule,
                            },
                        ],
                    },
                    {
                        headers: {
                            Authorization: this.createHmacHash(
                                this.$store.state.user.adfAdminUser,
                                "-POST-/addressflow/v1/proxy"
                            ),
                        },
                    }
                )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.success === true) {
                        this.$refs["create-new-modal"].hide();
                        this.fetchData(1);
                    } else {
                        this.showCreateErrorMsg = true;
                        if (data.errors && data.errors.name) {
                            this.createErrorMsg = "Ungültiger Name.";
                        }
                    }
                });
        },
        addPool(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.showCreateErrorMsg = false;

            if (!this.selectedExportRule) {
                this.showExportRuleErrorMsg = true;
                return false;
            }

            if (this.newTitle.length < 3) {
                this.showCreateErrorMsg = true;
                this.createErrorMsg = "Mindestens 3 Zeichen eingeben.";
                return false;
            }

            this.$http
                .post(
                    "proxy/" + this.activePoolProxy + "/addnewpool",
                    {
                        name: this.newTitle,
                        export_rule: this.selectedExportRule,
                    },
                    {
                        headers: {
                            Authorization: this.createHmacHash(
                                this.$store.state.user.adfAdminUser,
                                "-POST-/addressflow/v1/proxy/" + this.activePoolProxy + "/addnewpool"
                            ),
                        },
                    }
                )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.success === true) {
                        this.$refs["add-pool-modal"].hide();
                        this.fetchData(1);
                    } else {
                        this.showCreateErrorMsg = true;
                        if (data.errors && data.errors.name) {
                            this.createErrorMsg = "Ungültiger Name.";
                        }
                    }
                });
        },
        downloadSwagger(poolProxyKey) {
            this.$http
                .get(`proxy/${poolProxyKey}/swagger`)
                .then(response => {
                    let blob = new Blob([JSON.stringify(response.data, undefined, 2)], {
                        type: 'application/json'
                    });
                    FileSaver.saveAs(blob, 'adf_user_data_'+poolProxyKey+'.json');
                });
        }
    },
};
</script>
