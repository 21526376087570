const initialFilter = {
  searchValue: '',
  status: '',
  exportRule: '',
}

const initialSort = {
  field: 'id',
  desc: true,
}

const poolStore = {
  namespaced: true,
  state: () => ({
    currentPage: 1,
    filter: initialFilter,
    sort: initialSort,
    poolList: [],
    isPoolListInitialized: false
  }),
  mutations: {
    setCurrentPage (state, currentPage) {
      state.currentPage = currentPage
    },
    setFilter (state, filter) {
      state.filter = filter;
    },
    setSort (state, sort) {
      state.sort = sort;
    },
    setPoolList (state, poolList) {
      state.poolList = poolList;
    },
    setPoolListInitialized (state, isPoolListInitialized) {
      state.isPoolListInitialized = isPoolListInitialized;
    },
  },
  actions: {
    reset(state) {
      state.commit('setFilter', initialFilter);
      state.commit('setSort', initialSort);
    }
  },
  getters: {
    currentPage: (state) => state.currentPage,
    filter: (state) => state.filter
  }
};

export default poolStore;
