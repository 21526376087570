<template>
  <div class="container">

    <div class="row mt-3 align-items-center">
      <ul>
        <li>
          <strong>Dublettencheck für Pool-Key:</strong>
          {{this.poolKey}}
        </li>
      </ul>
    </div>
    <div class="row my-3 align-right">
      <b-button class="ml-auto" variant="danger" @click="deleteConfig()"">Löschen</b-button>
    </div>
    <div class="row">
      <v-jsoneditor v-model="duplicateEliminationConfig" :plus="false" height="500px" @error="onError" :options="{mode: 'code'}"></v-jsoneditor>
    </div>
    <div class="row my-3">
      <div class="col">
        <b-alert
          variant="success"
          :show="alertDismissCountDown"
          fade
          dismissible
          @dismissed="alertDismissCountDown=0; saveButtonDisabled=false"
          @dismiss-count-down="countDownChanged"
        >Die Dublettencheck-Einstellungen wurden erfolgreich gespeichert.</b-alert>
        <b-alert v-model="showError" variant="danger" dismissible>
          {{this.errorMsg}}
        </b-alert>
      </div>
    </div>
    <div class="row my-3">
      <b-button variant="primary" @click="save()" :disabled="saveButtonDisabled">Speichern</b-button>
    </div>
  </div>
</template>

<script>
import VJsoneditor from "v-jsoneditor";

export default {
  data() {
    return {
      poolKey: null,
      saveButtonDisabled: false,
      duplicateEliminationConfig: null,
      alertDismissCountDown: 0,
      errorMsg: '',
      showError: false,
    };
  },
  components: {
    VJsoneditor
  },
  created() {
    this.poolKey = this.$route.params.poolKey;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$http
        .get("pool/" + this.poolKey + "/duplicate_elimination", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/pool/" + this.poolKey + "/duplicate_elimination"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.duplicateEliminationConfig = data;
        });
    },
    onError() {
      console.log("error");
    },
    save() {
      this.saveButtonDisabled = true;
      this.errorMsg = '';
      this.showError = false;
      this.$http
        .post("pool/" + this.poolKey + "/duplicate_elimination", this.duplicateEliminationConfig, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-POST-/addressflow/v1/pool/" + this.poolKey + "/duplicate_elimination"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.alertDismissCountDown = 2;
          } else {
            console.error(data.message);
            this.errorMsg = 'Fehler beim Speichern: ' + data.message;
            this.showError = true;
            this.saveButtonDisabled = false;
          }
        })
        .catch((error) =>  {
          console.error(error);
          this.errorMsg = 'Allgemeiner Fehler: ' + error;
          this.showError = true;
          this.saveButtonDisabled = false;
        });
    },
    deleteConfig() {
      this.showError = false;
      this.$http
        .delete("pool/" + this.poolKey + "/duplicate_elimination", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-DELETE-/addressflow/v1/pool/" + this.poolKey + "/duplicate_elimination"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.alertDismissCountDown = 2;
            this.fetchData();
          } else {
            console.error(data.message);
            this.errorMsg = 'Fehler beim Löschen: ' + data.message;
            this.showError = true;
          }
        })
      .catch((error) =>  {
        console.error(error);
        this.errorMsg = 'Allgemeiner Fehler: ' + error.status + ' ' + error.statusText + ' ' + error;
        this.showError = true;
      });
    },
    countDownChanged(dismissCountDown) {
      this.alertDismissCountDown = dismissCountDown;
    }
  }
};
</script>
