<template>
    <div class="container">
        <div class="float-right">
            <b-button-close @click="$router.replace('/emailtemplates')">
                <h2 aria-hidden="true">&times;</h2>
            </b-button-close>
        </div>
        <div class="row my-4">
        </div>

        <validation-observer ref="form" v-slot="{ handleSubmit }">
            <b-form
                @submit.stop.prevent="handleSubmit(save)"
                class="justify-content-center"
            >
                <validation-provider
                    name="Name"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                >
                    <b-form-group
                        id="name-group-1"
                        label="Name"
                        label-for="name"
                    >
                        <b-form-input
                            id="name"
                            name="name"
                            placeholder="Name"
                            v-model="form.name"
                            :state="getValidationState(validationContext)"
                            aria-describedby="name-live-feedback"
                            type="text"
                            autofocus
                            :readonly="isTemplateArchived"
                        ></b-form-input>

                        <b-form-invalid-feedback id="name-live-feedback">{{
                                validationContext.errors[0]
                            }}
                        </b-form-invalid-feedback>
                    </b-form-group>

                </validation-provider>

                <validation-provider
                    name="Subject"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                >

                    <b-form-group
                        id="subject-group-1"
                        label="Subject"
                        label-for="subject"
                    >
                        <b-form-input
                            id="subject"
                            name="subject"
                            placeholder="Subject"
                            v-model="form.subject"
                            :state="getValidationState(validationContext)"
                            aria-describedby="subject-live-feedback"
                            type="text"
                            :readonly="isTemplateArchived"
                        ></b-form-input>

                        <b-form-invalid-feedback id="subject-live-feedback">{{
                                validationContext.errors[0]
                            }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                    name="sendername"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                >

                    <b-form-group
                        id="sendername-group-1"
                        label="Absendername"
                        label-for="sendername"
                    >
                        <b-form-input
                            id="sendername"
                            name="sendername"
                            placeholder="Absendername"
                            v-model="form.sender_name"
                            :state="getValidationState(validationContext)"
                            aria-describedby="sendername-live-feedback"
                            type="text"
                            :readonly="isTemplateArchived"
                        ></b-form-input>

                        <b-form-invalid-feedback id="sendername-live-feedback">{{
                                validationContext.errors[0]
                            }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                    name="senderemail"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                >
                    <b-form-group
                        id="senderemail-group-1"
                        label="Absender E-Mail"
                        label-for="senderemail"
                    >
                        <b-form-input
                            id="senderemail"
                            name="senderemail"
                            placeholder="Absender E-Mail"
                            v-model="form.sender_email"
                            :state="getValidationState(validationContext)"
                            aria-describedby="senderemail-live-feedback"
                            type="text"
                            :readonly="isTemplateArchived"
                        ></b-form-input>

                        <b-form-invalid-feedback id="senderemail-live-feedback">{{
                                validationContext.errors[0]
                            }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                    name="adminemail"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                >
                    <b-form-group
                        id="adminemail-group-1"
                        label="Administrator E-Mail"
                        label-for="adminemail"
                    >
                        <b-form-input
                            id="adminemail"
                            name="adminemail"
                            placeholder="Administrator E-Mail"
                            v-model="form.admin_email"
                            :state="getValidationState(validationContext)"
                            aria-describedby="adminemail-live-feedback"
                            type="text"
                            :readonly="isTemplateArchived"
                        ></b-form-input>

                        <b-form-invalid-feedback id="adminemail-live-feedback">{{
                                validationContext.errors[0]
                            }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                    name="templateDataMapping"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                >
                    <div class="row">
                        <p>Daten-Mapping</p>
                        <v-jsoneditor v-model="form.template_data_mapping" :plus="false" height="500px" @error="onError"
                                      :options="{mode: 'code'}"
                                      :aria-readonly="isTemplateArchived"
                        ></v-jsoneditor>
                    </div>
                </validation-provider>

                <validation-provider
                    name="htmlTemplate"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                >
                    <div class="row my-4">
                        <p>Template </p>
                        <MonacoEditor
                            height="500"
                            theme="vs-dark"
                            language="handlebars"
                            :options="editorOptions"
                            :value="form.html_body"
                            :editorMounted="onEditorMounted"
                            @change="onChange"
                            :aria-readonly="isTemplateArchived"
                        ></MonacoEditor>
                    </div>
                </validation-provider>

                <div class="row">
                    <p>Preview</p>
                    <iframe :srcdoc="previewHtml" id="htmlPreview" style="width: 100%; height: 300px;"
                            :aria-disabled="isTemplateArchived"
                    ></iframe>
                </div>

                <b-alert class="mt-3" variant="danger" :show="emailTemplateToEditHasErrors" fade>
                    Fehler beim Speichern.
                    <pre id="email_templates_errors_json">{{
                            JSON.stringify(emailTemplateToEditErrors, undefined, 2)
                        }}</pre>
                </b-alert>

                <div class="row my-3">
                    <b-button v-if="!isTemplateArchived" type="submit" variant="primary" :disabled="loading">
                        <b-spinner v-if="loading" small></b-spinner>
                        {{ (isTemplateToCopy) ? 'Template mit neuer ID anlegen' : 'Template aktualisieren (neue Version anlegen)' }}
                    </b-button>
                </div>

            </b-form>
        </validation-observer>

    </div>
</template>

<script>
import VJsoneditor from "v-jsoneditor";
import MonacoEditor from "monaco-editor-vue";
import 'monaco-editor/esm/vs/basic-languages/handlebars/handlebars.contribution';

export default {
    created() {
        this.templateKey = this.$route.params.templateKey;
        this.isTemplateToCopy = (this.$route.name === 'copy_email_template');
        this.fetchData();
    },
    data() {
        return {
            error: false,
            loading: false,
            emailTemplateToEditHasErrors: false,
            emailTemplateToEditErrors: [],

            form: {
                name: "",
                subject: "",
                sender_name: "",
                sender_email: "",
                admin_email: "",
                template_data_mapping: "",
                html_body: '',
                text_body: '',
            },
            isTemplateArchived: false,
            isTemplateToCopy: false,
            previewHtml: '',
            templateKey: '',
            editorOptions: {},
        };
    },
    components: {
        MonacoEditor,
        VJsoneditor
    },
    methods: {
        onChange(value) {
            this.form.html_body = value;
            this.previewHtml = value;
        },
        onEditorMounted(editor, monaco) {
            let val = this.form.html_body.replace(/\\r\\n/g, "\r\n");
            val = val.replace(/\\t/g, "\t");
            editor.setValue(val);
        },
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        fetchData() {
            this.$http
                .get(this.$msgFlowUrl + "templates/" + this.templateKey, {
                    headers: {
                        Authorization: this.$msgFlowToken
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    this.form = data.template;
                    this.isTemplateArchived = data.template.archived;
                });
        },
        onError() {
            console.log("error");
        },
        save() {
            this.loading = true;
            let requestMethod = 'put';
            let templateKeyPath = '/' + this.templateKey;
            if (this.isTemplateToCopy) {
                // create a new one instead of updating the selected template
                requestMethod = 'post';
                templateKeyPath = '';
            }
            this.$http[requestMethod](this.$msgFlowUrl + "templates" + templateKeyPath, this.form, {
                    headers: {
                        Authorization: this.$msgFlowToken
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    if (data.success === true) {
                        this.$router.replace('/emailtemplates');
                    } else {
                        this.emailTemplateToEditHasErrors = true;
                    }
                })
                .catch((errResponse) => {
                    this.loading = false;
                    this.emailTemplateToEditHasErrors = true;
                    if (errResponse.bodyText) {
                        const responseBody = JSON.parse(errResponse.bodyText);
                        this.emailTemplateToEditErrors = responseBody.messages;

                    }
                });
        }
    }
};
</script>
