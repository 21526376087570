<template>
    <nav aria-label="Page navigation">
        <ul class="pagination">
            <li
                class="page-item"
                v-bind:class="{ disabled: currentPage === 1 }"
                @click="prevPage">
                <a class="page-link" href="" aria-label="Previous" @click.prevent>
                    <span aria-hidden="true">&laquo;</span>
                </a>
            </li>
            <li
                v-for="n in pages"
                class="page-item"
                v-bind:class="{ active: currentPage === n }"
                @click="goToPage(n)">
                <a class="page-link" href="" @click.prevent>{{ n }}</a>
            </li>
            <li class="page-item"
            v-bind:class="{ disabled: currentPage === numberOfPages }"
            @click="nextPage">
                <a class="page-link" href="" aria-label="Next" @click.prevent>
                    <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    props: {
        totalNumberOfItems: Number,
        items: Array,
        limitPerPage: Number,
        startOnPage: {
          type: Number,
          default: 1
        },
        reset: Boolean
    },
    data () {
        return {
            numberOfPages: 0,
            pagesArray: [],
            currentPage: this.startOnPage
        }
    },
    computed: {
        pages() {
            this.refresh();
            return this.pagesArray;
        }
    },
    methods: {
        refresh() {
            if (true === this.reset) {
                this.currentPage = 1;
            }

            this.pagesArray = [];
            this.numberOfPages = Math.ceil(this.totalNumberOfItems / this.limitPerPage);
            let max = this.numberOfPages < 7 ? this.numberOfPages : 7;

            if (this.numberOfPages > 7) {
                let isEndOfList = this.currentPage + 5 >= this.numberOfPages;

                let minLeft = (this.currentPage > 5) ? this.currentPage - 3 : 1;
                let maxStepsToRight = isEndOfList ? this.numberOfPages - this.currentPage : 3;
                let maxRight = (this.currentPage > 5) ? this.currentPage + maxStepsToRight : max;

                let i = minLeft;
                while (i <= maxRight) {
                    this.pagesArray.push(i++);
                }

                if (this.currentPage > 5) {
                    this.pagesArray.splice(0, 0, 1);
                    this.pagesArray.splice(1, 0, '...');
                }

                if (!isEndOfList) {
                    this.pagesArray.push('...');
                    this.pagesArray.push(this.numberOfPages);
                }
            } else {
                this.pagesArray = [];
                let i = 1;
                while (i <= this.numberOfPages) {
                    this.pagesArray.push(i++);
                }
            }
        },
        nextPage() {
            if (this.currentPage !== this.numberOfPages) {
                this.currentPage++;
                this.$emit('currentPageWasChanged', this.currentPage);
            }
        },
        prevPage() {
            if (this.currentPage !== 1) {
                this.currentPage--;
                this.$emit('currentPageWasChanged', this.currentPage);
            }
        },
        goToPage(page) {
            if (Number.isInteger(page) && page !== this.currentPage) {
                this.currentPage = page;
                this.$emit('currentPageWasChanged', page);
            }
        }
    }
}
</script>
