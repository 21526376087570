<template>
    <div>
        <div class="row my-4 filters">
            <div class="col-md-12">
                <form class="form-inline float-right">
                    <div class="mx-3">
                        <button class="btn btn-dark" @click="resetFilter">Filter löschen</button>
                    </div>
                    <div class="form-group mx-3">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Suche..."
                            v-model="searchValue"
                        >
                        <button @click.prevent="submit" class="btn btn-primary btn-dark">Suche</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            searchValue: this.$store.state.poolProxy.filter.searchValue,
        }
    },
    methods: {
        commitFilterParams() {
            this.$store.commit('poolProxy/setFilter', {
              searchValue: this.searchValue,
            })
        },
        submit() {
            this.commitFilterParams();
            this.$emit('search', 1, true);
        },
        resetFilter() {
            this.$store.dispatch('poolProxy/reset');
            this.searchValue = '';
            this.$emit('search', 1, true);
        },
    }
}
</script>
