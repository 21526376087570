<template>
    <div class="container-fluid">
        <div class="my-4">
            <div class="position-absolute" style="z-index: 100">
                <b-button variant="primary" @click="$router.replace('/emailtemplate/new')">Erstellen</b-button>
            </div>

            <search-bar @filter-changed="onFilterChanged"></search-bar>
        </div>

        <b-table id="emailTemplates"
                 hover
                 :items="filteredItems"
                 :fields="tableFields"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 sort-icon-left
                 :sort-compare="customSortWithDates"
                 @sort-changed="onSortChanged"
                 :per-page="itemsPerPage"
                 :current-page="currentPage"
                 custom-foot
        >
            <template v-slot:cell(template_id)="data">
                <span :class="data.item.archived ? 'disabled' : ''">
                  {{ (data.value) }}
                </span>
            </template>

            <template v-slot:cell(version)="data">
                <span :class="data.item.archived ? 'disabled' : ''">
                  {{ (data.value) }}
                </span>
            </template>

            <template v-slot:cell(template_key)="data">
                <span :class="data.item.archived ? 'disabled' : ''">
                  {{ (data.value) }}
                </span>
            </template>

            <template v-slot:cell(created_at)="data">
                <span :class="data.item.archived ? 'disabled' : ''">
                  {{ (data.value) }}
                </span>
            </template>

            <template v-slot:cell(name)="data">
                <span
                    v-b-tooltip.hover
                    :title="data.value"
                >
                    <span :class="data.item.archived ? 'disabled' : ''">
                      {{ truncateText(data.value, 70) }} <!-- print truncated name -->
                    </span>
                </span>
            </template>

            <template v-slot:cell(used_via_finisher)="data">
                <span :id="'finisher-' + data.item.template_key">
                  <span v-for="(pool, index) in data.item.used_via_finisher" :key="index">
                    <router-link :to="{ name: 'pool_proxy_settings', params: { poolProxyKey: pool.proxy_key }}">
                        <span
                            v-b-tooltip.hover
                            :title="pool.name"
                        >
                          <b>{{ truncateText(pool.name) }}</b>
                            <br>
                            ({{ pool.pool_key }}, {{ pool.proxy_key }})
                        </span>
                    </router-link>
                      <!-- add a line break between each pool -->
                    <span v-if="index < data.item.used_via_finisher.length - 1"><br></span>
                  </span>
                </span>
            </template>

            <template v-slot:cell(used_via_pool_config)="data">
                <span :id="'config-' + data.item.template_key">
                  <span v-for="(pool, index) in data.item.used_via_pool_config" :key="index">
                    <router-link :to="{ name: 'pool_settings', params: { poolKey: pool.pool_key }}">
                        <span
                            v-b-tooltip.hover
                            :title="pool.pool_name"
                        >
                            <b>{{ truncateText(pool.pool_name) }}</b>
                            <br>
                            ({{ pool.pool_key }}, {{ pool.proxy_key }})
                        </span>
                    </router-link>
                      <!-- add a line break between each pool -->
                    <span v-if="index < data.item.used_via_pool_config.length - 1"><br></span>
                  </span>
                </span>
            </template>

            <template v-slot:cell(replace)="data">
                <b-button-group
                    v-if="(data.item.used_via_finisher.length || data.item.used_via_pool_config.length) && data.item.archived"
                >
                    <a
                        href="#"
                        @click.prevent="replaceTemplateId(data.item)"
                        v-b-tooltip.hover
                        title="Pools auf neueste Version stellen."
                    >
                        <font-awesome-icon
                            v-show="(data.item.template_key)"
                            :icon="['fas', 'arrow-up']"
                        />
                    </a>
                </b-button-group>
            </template>

            <template v-slot:cell(edit)="data">
                <b-button-group>
                    <router-link :to="{ name: 'edit_email_template', params: { templateKey: data.item.template_key}}">
                        <a v-if="!data.item.archived"
                           v-b-tooltip.hover
                           title="Dieses E-Mail-Template aktualisieren und eine neue Version erstellen."
                        >
                            <font-awesome-icon
                                v-show="(data.item.template_key)"
                                :icon="['fas', 'pen']"
                            />
                        </a>
                        <a v-if="data.item.archived"
                           v-b-tooltip.hover
                           title="Dieses E-Mail-Template ansehen."
                        >
                            <font-awesome-icon
                                v-show="(data.item.template_key)"
                                :icon="['fas', 'eye']"
                            />
                        </a>
                    </router-link>
                </b-button-group>
            </template>

            <template v-slot:cell(copy)="data">
                <b-button-group v-if="!data.item.archived">
                    <router-link :to="{ name: 'copy_email_template', params: { templateKey: data.item.template_key}}">
                        <a v-b-tooltip.hover
                           title="Dieses E-Mail-Template als Vorlage verwenden und eine neue Template-Id generieren."
                        >
                            <font-awesome-icon v-show="(data.item.template_key)" :icon="['fas', 'copy']"/>
                        </a>
                    </router-link>
                </b-button-group>
            </template>

            <template v-slot:custom-foot>
                <tr>
                    <td :colspan="tableFields.length">
                        <strong>Anzahl: {{ filteredRows }}</strong>
                    </td>
                </tr>
            </template>
        </b-table>

        <b-pagination
            v-model="currentPage"
            :total-rows="filteredRows"
            :per-page="itemsPerPage"
            aria-controls="emailTemplates"
            @change="onPageChanged"
        ></b-pagination>

    </div>
</template>

<script>
import {BIcon, BIconPlus, BIconPencil, BIconDiagram2, BIconBraces} from "bootstrap-vue";
import Paginator from "./Paginator.vue";
import SearchBar from "./SearchBarEmailTemplates.vue";
import FileSaver from "file-saver";
/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from "@fortawesome/free-solid-svg-icons";
/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import data from "./Data.vue";

library.add(fas)

export default {
    components: {
        BIcon,
        BIconPlus,
        BIconPencil,
        BIconDiagram2,
        BIconBraces,
        FileSaver,
        poolProxyPaginator: Paginator,
        searchBar: SearchBar,
        fontAwesomeIcon: FontAwesomeIcon
    },
    data() {
        return {
            filter: {
                name: '',        // Filter for Name and Template key
                startDate: null, // Start date filter for creation date
                endDate: null,   // End date filter for creation date
            },
            filteredRows: 0,
            itemsPerPage: 15,
            currentPage: this.$store.state.emailTemplate.currentPage,
            sortBy: this.$store.state.emailTemplate.sort.field,
            sortDesc: this.$store.state.emailTemplate.sort.desc,
            tableFields: [
                {
                    key: "template_id",
                    label: "Templ.-ID",
                    sortable: true
                },
                {
                    key: "version",
                    label: "Vers.",
                    sortable: true
                },
                {
                    key: "template_key",
                    label: "Template-Key",
                    sortable: true
                },
                {
                    key: "name",
                    label: "Name",
                    sortable: true
                },
                {
                    key: "used_via_finisher",
                    label: "In Pool verwendet",
                },
                {
                    key: "used_via_pool_config",
                    label: "In Export verwendet",
                },
                {
                    key: "created_at",
                    label: "Erstelldatum",
                    sortable: true
                },
                {
                    key: "replace",
                    label: "",
                },
                {
                    key: "edit",
                    label: "",
                },
                {
                    key: "copy",
                    label: "",
                },
            ],
            filteredItems: [],
            displayTemplatesList: [],
            rawTemplatesDynamoDb: [],
            rawTemplatesUsedViaFinisher: [],
            rawTemplatesUsedViaSpecialPoolConfig: [],
            totalNumberOfEmailTemplates: 0,
            newTitle: "",
            showCreateErrorMsg: false,
            createErrorMsg: "",
        };
    },
    created() {
        this.fetchData();
        if (this.$route.params.templateKey) {
            this.filter.name = this.$route.params.templateKey;
            this.$store.commit('emailTemplate/setFilter', this.filter)
        }

        this.filter = this.$store.state.emailTemplate.filter
        this.currentPage = this.$store.state.emailTemplate.currentPage
        this.filteredRows = this.filteredItems.length
    },
    methods: {
        truncateText(text, maxLength = 40) {
            return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
        },
        mergeUsedViaFinisher(emailTemplate) {
            // The templatesUsedViaFinisher.finisherTemplates-Array contains all Pools with the
            // corresponding proxy-information and the templates
            // used. Both template_key and template_key_followup could be set (with different values).
            // Format of this array see below.
            // To reduce the amounts of for-loops the templatesUsedViaFinisher.finisherTemplates-Array
            // will be reduced during reading as soon as
            // both template_key and template_key_followup has been set to null.
            //
            // Format of the templatesUsedViaFinisher.finisherTemplates-array:
            // "finisherTemplates": [
            //     {
            //         "pool_key": "lA3b1VgK",
            //         "name": "Testpool",
            //         "proxy_id": 1,
            //         "proxy_key": "-Aas68J6I",
            //         "template_key": "fpx0MVsycqPzUYyD",
            //         "template_key_followup": null
            //     },
            //     {
            //         "pool_key": "gGOHKuGC",
            //         "name": "Proxy2",
            //         "proxy_id": 2,
            //         "proxy_key": "-3TtDv1Dd",
            //         "template_key": "SrcFROAPuankZTtI",
            //         "template_key_followup": null
            //     },
            //     {
            //         "pool_key": "UOnTLLYf",
            //         "name": "Pool Export Mail",
            //         "proxy_id": 2,
            //         "proxy_key": "-3TtDv1Dd",
            //         "template_key": "SrcFROAPuankZTtI",
            //         "template_key_followup": null
            //     },
            //     {
            //         "pool_key": "C1lZUWc4",
            //         "name": "MonitoringPool",
            //         "proxy_id": 3,
            //         "proxy_key": "-TuwjJzIJ",
            //         "template_key": "vNc3od6nUnKPiLhY",
            //         "template_key_followup": null
            //     }
            // ]
            if (this.rawTemplatesUsedViaFinisher.finisherTemplates.length > 0) {
                for (let i = 0; i < this.rawTemplatesUsedViaFinisher.finisherTemplates.length; i++) {
                    let proxy = this.rawTemplatesUsedViaFinisher.finisherTemplates[i];
                    if (emailTemplate.template_key === proxy.template_key || emailTemplate.template_key === proxy.template_key_followup) {
                        emailTemplate['used_via_finisher'].push(proxy);
                        if (emailTemplate.template_key === proxy.template_key) {
                            this.rawTemplatesUsedViaFinisher.finisherTemplates[i].template_key = null;
                        }
                        if (emailTemplate.template_key === proxy.template_key_followup) {
                            this.rawTemplatesUsedViaFinisher.finisherTemplates[i].template_key_followup = null;
                        }
                        // if both template-keys of the pool entry has been read the entry will be deleted
                        if (this.rawTemplatesUsedViaFinisher.finisherTemplates[i].template_key === null && this.rawTemplatesUsedViaFinisher.finisherTemplates[i].template_key_followup === null) {
                            this.rawTemplatesUsedViaFinisher.finisherTemplates.splice(i, 1);
                            i--;
                        }
                    }
                }
            }
            return emailTemplate
        },
        onPageChanged(page) {
            this.currentPage = page
            this.$store.commit('emailTemplate/setCurrentPage', this.currentPage);
        },
        onSortChanged({sortBy, sortDesc}) {
            this.$store.commit('emailTemplate/setSort', {
                field: sortBy,
                desc: sortDesc,
            })
        },
        onFilterChanged() {
            this.filter = this.$store.state.emailTemplate.filter
            // Apply custom filter based on filterTerm
            this.filteredItems = this.displayTemplatesList.filter(item => {
                return this.customFilterFunction(item)
            });

            this.updatePagination(this.filteredItems)
        },
        updatePagination(filteredItems) {
            // this.filter = this.$store.state.emailTemplate.filter
            this.$nextTick(() => {
                this.currentPage = this.$store.state.emailTemplate.currentPage
                this.sortBy = this.$store.state.emailTemplate.sort.field
                this.sortDesc = this.$store.state.emailTemplate.sort.desc
            })
            this.filteredRows = filteredItems.length
        },
        customFilterFunction(item) {
            // format the created date in a comparable format
            const itemDateParts = item.created_at.split(' ');
            const [day, month, year] = itemDateParts[0].split('.');
            const itemDate = new Date(`${year}-${month}-${day}T${itemDateParts[1]}`);
            const itemDateString = itemDate.toISOString().split('T')[0];
            const fromDateString = this.filter.startDate ? new Date(this.filter.startDate).toISOString().split('T')[0] : null;
            const untilDateString = this.filter.endDate ? new Date(this.filter.endDate).toISOString().split('T')[0] : null;

            // Date filtering logic
            let dateMatches = true;
            if (fromDateString && untilDateString) {
                dateMatches = itemDateString >= fromDateString && itemDateString <= untilDateString;
            } else if (fromDateString) {
                dateMatches = itemDateString >= fromDateString;
            } else if (untilDateString) {
                dateMatches = itemDateString <= untilDateString;
            }

            // Default column filter logic with value from input field
            let inputFieldMatches = true;
            let matchesTemplateUsage = false;
            let matchesString = false;
            let matchesIdOrVersion = false;
            if (this.filter.name) {
                const filterText = this.filter.name.toLowerCase();
                inputFieldMatches = Object.keys(item).some(key => {
                    // date is filtered above
                    if (typeof item[key] === 'string' && key !== 'created_at') {
                        matchesString = item[key].toLowerCase().includes(filterText);
                    }
                    // filter template_id and version
                    if (typeof item[key] === 'number' && (key === 'template_id' || key === 'version')) {
                        matchesIdOrVersion = (item[key] === parseInt(filterText));
                    }
                    // filter proxy and pool information
                    if (key === 'used_via_finisher' || key === 'used_via_pool_config') {
                        const itemArray = item[key]
                        let filterMatchesPoolKey = false;
                        let filterMatchesPoolName = false;
                        let filterMatchesProxyKey = false;
                        if (itemArray.length > 0) {
                            matchesTemplateUsage = Object.keys(itemArray).some(key2 => {
                                if (itemArray[key2].pool_key) {
                                    filterMatchesPoolKey = itemArray[key2].pool_key.toLowerCase().includes(filterText);
                                }
                                if (itemArray[key2].pool_name) {
                                    filterMatchesPoolName = itemArray[key2].pool_name.toLowerCase().includes(filterText);
                                }
                                if (itemArray[key2].proxy_key) {
                                    filterMatchesProxyKey = itemArray[key2].proxy_key.toLowerCase().includes(filterText);
                                }
                                return filterMatchesPoolKey
                                    || filterMatchesPoolName
                                    || filterMatchesProxyKey;
                            })
                        }
                    }
                    return matchesString || matchesTemplateUsage || matchesIdOrVersion;
                });
            }

            // Return true only if both date and text filters match
            return dateMatches && inputFieldMatches;
        },
        mergeUsedViaPoolConfig(emailTemplate) {
            // The templatesUsedViaSpecialPoolConfig.configuredTemplates-Array contains all Pools with the
            // corresponding proxy-information and the templates
            // used. Both template_key and template_key_followup could be set (with different values).
            // Format of this array see below.
            // To reduce the amounts of for-loops the templatesUsedViaSpecialPoolConfig.configuredTemplates-Array
            // will be reduced during reading as soon as
            // both template_key and template_key_followup has been set to null.
            //
            // Format of the templatesUsedViaSpecialPoolConfig.configuredTemplates-array:
            // {
            //     "configuredTemplates": [
            //     {
            //         "template_key": "SrcFROAPuankZTtI",
            //         "pool_key": "gGOHKuGC",
            //         "pool_name": "Proxy2"
            //     },
            //     {
            //         "template_key": "SrcFROAPuankZTtI",
            //         "pool_key": "hPi2NxRL",
            //         "pool_name": "Test E-Mail-Export"
            //     },
            //     {
            //         "template_key": "SrcFROAPuankZTtI",
            //         "pool_key": "UOnTLLYf",
            //         "pool_name": "Pool Export Mail"
            //     },
            //     {
            //         "template_key": "aCOIxlr0miBHZdjo",
            //         "pool_key": "Jou3G8cc",
            //         "pool_name": "Pool mit FollowUp"
            //     }
            // ]
            // }
            if (this.rawTemplatesUsedViaSpecialPoolConfig.configuredTemplates.length > 0) {
                for (let i = 0; i < this.rawTemplatesUsedViaSpecialPoolConfig.configuredTemplates.length; i++) {
                    let groupedPoolList = this.rawTemplatesUsedViaSpecialPoolConfig.configuredTemplates[i];
                    if (emailTemplate.template_key === groupedPoolList.template_key) {
                        emailTemplate['used_via_pool_config'].push(groupedPoolList);
                        this.rawTemplatesUsedViaSpecialPoolConfig.configuredTemplates.splice(i, 1);
                        i--;
                    }
                }
            }
            return emailTemplate
        },
        updateTable() {
            const emailTemplatesArray = [];
            for (let i in this.rawTemplatesDynamoDb.templates) {
                let emailTemplate = this.rawTemplatesDynamoDb.templates[i];
                emailTemplate['used_via_finisher'] = [];
                emailTemplate = this.mergeUsedViaFinisher(emailTemplate);
                emailTemplate['used_via_pool_config'] = [];
                emailTemplate = this.mergeUsedViaPoolConfig(emailTemplate);
                emailTemplatesArray.push(emailTemplate);
            }

            this.displayTemplatesList = emailTemplatesArray;
            this.onFilterChanged()
            // this.filteredItems = this.displayTemplatesList;
            this.totalNumberOfEmailTemplates = this.rawTemplatesDynamoDb.total;
        },
        getNewestTemplateVersionForTemplateId(templateId) {
            return this.rawTemplatesDynamoDb.templates
                .filter(template => template.template_id === templateId)
                .reduce((newest, template) => {
                    if (template.version > newest.version) {
                        return template
                    }
                    return newest
                })
        },
        async replaceTemplateId(oldDisplayTemplate) {
            // console.log('oldTemplate', oldTemplate)

            // search for latest version of that TemplateId branch
            const newestTemplate = this.getNewestTemplateVersionForTemplateId(oldDisplayTemplate.template_id)
            // console.log('newestTemplate',newestTemplate)

            // collect all proxies to process
            let proxysToProcess = new Set()
            oldDisplayTemplate['used_via_finisher'].forEach(item => proxysToProcess.add(item.proxy_key))
            oldDisplayTemplate['used_via_pool_config'].forEach(item => proxysToProcess.add(item.proxy_key))

            // call the modifying API Call for all proxys
            const performApiCall = async (proxyKey, oldTemplateKey, newTemplateKey) => {
                const urlPath = `interface/poolproxy/${proxyKey}/changeEmailTemplate`
                await this.$http
                    .post(urlPath,
                        {
                            "oldEmailTemplateKey": oldTemplateKey,
                            "newEmailTemplateKey": newTemplateKey
                        },
                        {
                            headers: {
                                Authorization: this.createHmacHash(
                                    this.$store.state.user.adfAdminUser,
                                    `-POST-/addressflow/v1/${urlPath}`
                                ),
                            },
                        })
            }

            try {
                const promises = [...proxysToProcess].map(item => performApiCall(item, oldDisplayTemplate.template_key, newestTemplate.template_key));
                const results = await Promise.all(promises);
                // console.log('all updated', results)
                this.fetchData();
            } catch (error) {
                console.error('An error occurred:', error);
            }
        },
        async fetchData() {
            // left for search values - next jira ticket
            let params = {};

            let searchValue = this.$store.state.searchValue;
            if (searchValue) {
                params.searchValue = searchValue;
            }

            try {
                // get emails template out of AWS DynamoDB
                const responseTemplates = this.$http
                    .get(this.$msgFlowUrl + "templates", {
                        params: params,
                        headers: {
                            Authorization: this.$msgFlowToken
                        },
                    })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        this.rawTemplatesDynamoDb = data;
                    });

                // get email templates used in pools via finisher (ADF-Backend)
                const responseProxies = this.$http
                    .get("interface/poolproxy/templates", {
                        headers: {
                            Authorization: this.createHmacHash(
                                this.$store.state.user.adfAdminUser,
                                "-GET-/addressflow/v1/interface/poolproxy/templates"
                            ),
                        },
                    })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        this.rawTemplatesUsedViaFinisher = data;
                    })

                // get email templates which has been specified in the special pool config (ADF-Backend)
                const responseConfiguredPools = this.$http
                    .get("interface/pool/templates", {
                        headers: {
                            Authorization: this.createHmacHash(
                                this.$store.state.user.adfAdminUser,
                                "-GET-/addressflow/v1/interface/pool/templates"
                            ),
                        },
                    })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        this.rawTemplatesUsedViaSpecialPoolConfig = data;
                    })
                await Promise.all([responseTemplates, responseProxies, responseConfiguredPools]);

                this.updateTable();
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        customSortWithDates(a, b, key, sortDesc) {
            if (key === 'created_at') {
                const dateA = this.parseDate(a[key]);
                const dateB = this.parseDate(b[key]);

                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;

            }
            if (key === 'template_id') {
                if (a['template_id'] === b['template_id']) {
                    const returnValue = (sortDesc) ? 1 : -1;
                    if (a['version'] < b['version']) {
                        return returnValue * -1;
                    }
                    if (a['version'] > b['version']) {
                        return returnValue;
                    }
                } else {
                    if (a['template_id'] < b['template_id']) return -1;
                    if (a['template_id'] > b['template_id']) return 1;
                }
            }
            return null;
        },
        parseDate(dateString) {
            // dateString is in the format 'dd.mm.yyyy hh:ii:ss'
            const [datePart, timePart] = dateString.split(' ');
            const [day, month, year] = datePart.split('.');

            // Build an ISO-8601-Date as String and return
            return year + '-' + month + '-' + day + 'T' + timePart + 'Z';
        }
    },
};
</script>
