<template>
    <div class="container-fluid">
        <search-bar @search="onSearch" :show-filter="true"></search-bar>

        <b-table
            hover
            :items="pools"
            :per-page=limitPerPage
            :current-page=currentPage
            :fields="tableFields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @sort-changed="onSortChanged"
            sort-icon-left
            custom-foot
        >
            <template v-slot:cell(key)="data">
                <router-link :to="{ name: 'pool_settings', params: { poolKey: data.value }}">
                    {{ data.value }}
                </router-link>
            </template>
            <template v-slot:cell(status)="data">
                <b-button variant="default" :title="getTitleByStatus(data.value)" size="sm">
                    <b-icon-circle-fill :variant="getVariantByStatus(data.value)"></b-icon-circle-fill>
                </b-button>
            </template>
            <template v-slot:cell(pool_proxy)="data">
                {{ data.value.key }}
            </template>
            <template v-slot:cell(all_template_keys)="data">
                <span :id="'config-' + data.item.all_template_keys">
                  <span v-for="(currentTemplateKey, index) in data.item.all_template_keys" :key="index">
                    <router-link :to="{ name: 'show_filtered_email_template', params: { templateKey: currentTemplateKey.key }}">
                      <b>{{ currentTemplateKey.type }}:</b> {{ currentTemplateKey.key }}
                    </router-link>
                      <!-- add a line break between each pool -->
                    <span v-if="index < data.item.all_template_keys.length - 1"><br></span>
                  </span>
                </span>
            </template>
            <template v-slot:cell(number_of_data_now)="data">
                {{ data.value | formatNumber }}
            </template>
            <template v-slot:cell(number_of_data_total)="data">
                {{ data.value | formatNumber }}
            </template>
            <template v-slot:cell(number_of_exported_data)="data">
                {{ data.value | formatNumber }}
            </template>
            <template v-slot:cell(optin_quota)="data">
                <router-link
                    :disabled="(data.value === 0)"
                    :class="(data.value === 0) ? 'disabled' : ''"
                    :event="(data.value === 0) ? '' : 'click'"
                    :to="{ name: 'pool_quotas', params: { poolKey: data.item.pool_key }}">
                    <a>{{ data.value }}</a>
                </router-link>
            </template>

            <template v-slot:cell(double_optin)="data">
                <font-awesome-icon v-show="(data.value)" :icon="['fas', 'check']" />
            </template>
            <template v-slot:cell(auto_export)="data">
                <font-awesome-icon v-show="(data.value)" :icon="['fas', 'check']" />
            </template>
            <template v-slot:cell(import_allowed)="data">
                <font-awesome-icon v-show="(data.value)" :icon="['fas', 'check']" />
            </template>

            <template v-if="$store.getters.userRole === 'AdfSystemAdmin'" v-slot:cell(export_settings)="data">
                <router-link :to="{ name: 'export_settings', params: { poolKey: data.value }}">
                    <font-awesome-icon v-show="(data.value)" :icon="['fas', 'pen']" />
                </router-link>
            </template>

            <template
                v-if="$store.getters.userRole === 'AdfSystemAdmin'"
                v-slot:cell(export_overwrites)="data"
            >
                <font-awesome-icon
                    v-show="(data.value)"
                    v-b-tooltip.hover
                    :id="'overwrite-' + data.index"
                    :icon="['fas', 'check']"
                />
                <b-tooltip :target="'overwrite-' + data.index" triggers="hover">
                  {{data.value}}
                </b-tooltip>
            </template>

            <template
                v-if="$store.getters.userRole === 'AdfSystemAdmin'"
                v-slot:cell(duplicate_elimination)="data"
            >
                <router-link :to="{ name: 'pool_duplicate_elimination', params: { poolKey: data.value.poolKey }}">
                    <font-awesome-icon v-show="(data.value.isSet)" :icon="['fas', 'pen']" />
                    <font-awesome-icon v-show="(!data.value.isSet)" :icon="['fas', 'plus']" />
                </router-link>
            </template>

            <template v-slot:cell(show_data)="data">
                <router-link :to="{ name: 'data', params: { poolKey: data.value }}">
                    <a>
                        <font-awesome-icon :icon="['fas', 'list']" />
                    </a>
                </router-link>
            </template>
            <template v-slot:cell(show_exports)="data">
                <router-link
                    :disabled="(data.value === 0)"
                    :class="(data.value === 0) ? 'disabled' : ''"
                    :event="(data.value === 0) ? '' : 'click'"
                    :to="{ name: 'pool_exports', params: { poolId: data.value }}">
                    <a>
                        <font-awesome-icon v-show="(data.value > 0)" :icon="['fas', 'list']" />
                    </a>
                </router-link>
            </template>
            <template v-slot:cell(stats)="data">
                <router-link
                    :disabled="(data.value === 0)"
                    :class="(data.value === 0) ? 'disabled' : ''"
                    :event="(data.value === 0) ? '' : 'click'"
                    :to="{ name: 'pool_stats', params: { poolKey: data.value }}">
                    <a>
                        <font-awesome-icon v-show="(data.value != 0)" :icon="['fas', 'chart-bar']" />
                    </a>
                </router-link>
            </template>

            <template v-slot:custom-foot>
                <tr>
                    <td :colspan="tableFields.length">
                        <strong>Anzahl: {{ poolCount }}</strong>
                    </td>
                </tr>
            </template>
        </b-table>

        <b-pagination
            v-model="currentPage"
            :total-rows="poolCount"
            :per-page="limitPerPage"
            @change="onPageChanged"
            aria-controls="my-table"
        ></b-pagination>
        <div class="d-flex justify-content-end">
            <b-button @click="fetchData()">Aktualisieren</b-button>
        </div>
    </div>
</template>

<script>
import {BIcon, BIconCircleFill} from "bootstrap-vue";
import SearchBar from "./SearchBarPool.vue";
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from "@fortawesome/free-solid-svg-icons";
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)

export default {
    components: {
        BIcon,
        BIconCircleFill,
        searchBar: SearchBar,
        fontAwesomeIcon: FontAwesomeIcon
    },
    data() {
        return {
            tableFields: [
                {
                    key: "id",
                    label: "ID",
                    sortable: true,
                },
                {
                    key: "status",
                    label: "Status",
                    class: "text-center",
                    sortable: true,
                },
                {
                    key: "pool_proxy",
                    label: "Pool Proxy",
                    sortable: true,
                },
                {
                    key: "key",
                    label: "Key",
                    sortable: true,
                },
                {
                    key: "export_rule",
                    label: "Export-Regel",
                    sortable: true,
                },
                {
                    key: "party",
                    label: "Ersteller",
                    sortable: true,
                },
                {
                    key: "name",
                    label: "Titel",
                    sortable: true,
                },
                {
                    key: "all_template_keys",
                    label: "E-Mail-Template",
                    sortable: true,
                },
                {
                    key: "number_of_data_now",
                    label: "aktuell",
                    class: "text-right",
                    sortable: true,
                },
                {
                    key: "number_of_data_total",
                    label: "insgesamt",
                    class: "text-right",
                    sortable: true,
                },
                {
                    key: "number_of_exported_data",
                    label: "exportiert",
                    class: "text-right",
                    sortable: true,
                },
                {
                    key: "optin_quota",
                    label: "Optin-Quote",
                    class: "text-right",
                    sortable: true,
                },
                {
                    key: "double_optin",
                    label: "DOI",
                    class: "text-center",
                    sortable: true,
                },
                {
                    key: "auto_export",
                    label: "Auto-Export",
                    class: "text-center",
                    sortable: true,
                },
                {
                    key: "import_allowed",
                    label: "CSV Importer",
                    class: "text-center",
                    sortable: true,
                },
                {
                    key: "export_settings",
                    label: "Export-Regeln",
                    class: "text-center",
                    sortable: false,
                },
                {
                    key: "export_overwrites",
                    label: "Export- Überschr.",
                    class: "text-center",
                    sortable: true,
                },
                {
                    key: "duplicate_elimination",
                    label: "Dubletten-Check",
                    class: "text-center",
                    sortable: true,
                },
                {
                    key: "show_data",
                    label: "Leads",
                    class: "text-center",
                    sortable: false,
                },
                {
                    key: "show_exports",
                    label: "Exporte",
                    class: "text-center",
                    sortable: true,
                },
                {
                    key: "stats",
                    label: "Insights",
                    class: "text-center",
                    sortable: true,
                }
            ],
            details: true,
            limitPerPage: 15,
            pools: [],
            currentPage: this.$store.state.pool.currentPage,
            sortBy: this.$store.state.pool.sort.field,
            sortDesc: this.$store.state.pool.sort.desc,
        };
    },
    created() {
      if (!this.$store.state.pool.isPoolListInitialized) {
          this.fetchData();
          this.$store.commit('pool/setPoolListInitialized', true);
      }
      this.pools = this.$store.state.pool.poolList
    },
    methods: {
        updateTable(data) {
            const poolsArray = [];
            for (let i in data.pools) {
                let pool = data.pools[i];

                console.log(pool)

                if (pool.locked) {
                    pool["_rowVariant"] = 'danger';
                }

                // injecting pool key to table cells
                pool["export_settings"] = pool.key;

                // create text for export overwrite tooltip
                const export_overwrites_map = {
                  export_definition: "Konfiguration",
                  export_schedule: "Zeiten",
                  export_structure: "Template"
                };
                 const overwrites_text = Object.keys(export_overwrites_map)
                  .filter(key => pool[key])
                  .map(key => export_overwrites_map[key])
                  .join(', ')
                pool["export_overwrites"] = overwrites_text ?  "Überschrieben: " + overwrites_text : ""

                pool["show_data"] = pool.key;
                pool["pool_key"] = pool.key;

                pool['duplicate_elimination'] = {
                    poolKey: pool.key,
                    isSet: !!pool.duplicate_elimination
                }

                let optinQuota = 0;
                if (pool["number_of_data_total"] > 0) {
                    optinQuota = pool["number_of_exported_data"] / pool["number_of_data_total"];
                }
                pool["optin_quota"] = (optinQuota * 100).toFixed(2) + "%";

                if (pool.extended_export_logging.enabled) {
                    pool["show_exports"] = pool.id;
                } else {
                    pool["show_exports"] = 0;
                }
                if (pool.statistics_existent) {
                    pool["stats"] = pool.key;
                }

                if (!pool.statistics_existent || pool.import_allowed) {
                    pool["stats"] = 0;
                    pool["optin_quota"] = '-'
                    pool["number_of_data_total"] = '-'
                    pool["number_of_exported_data"] = '-'
                }

                // get template keys out of pool config and finisher settings
                let templateKeyArray = []
                let dataArray = []

                if (pool.special_pool_config.doi_template_id) {
                    dataArray['type'] = 'Export'
                    dataArray['key'] = pool.special_pool_config.doi_template_id
                    templateKeyArray.push(dataArray)
                    dataArray = []
                }
                if (pool.finisher_settings.length) {
                    for (let k in pool.finisher_settings) {
                        let finisherSettings = pool.finisher_settings[k];
                        if (finisherSettings.config_parameters.template_key) {
                            dataArray['type'] = 'DOI'
                            dataArray['key'] = finisherSettings.config_parameters.template_key
                            templateKeyArray.push(dataArray)
                            dataArray = []
                        }
                        if (finisherSettings.config_parameters.follow_up_template_key) {
                            dataArray['type'] = 'FUp'
                            dataArray['key'] = finisherSettings.config_parameters.follow_up_template_key
                            templateKeyArray.push(dataArray)
                            dataArray = []
                        }
                    }
                }
                pool["all_template_keys"] = templateKeyArray;
                poolsArray.push(pool);
            }

            // set new data for table
            this.pools = poolsArray;
            this.$store.commit('pool/setPoolList', this.pools);

            // set page and sort after the table has processed the new data,
            // otherwise it will have no effect
            this.$nextTick(() => {
                this.currentPage = this.$store.state.pool.currentPage
                this.sortBy = this.$store.state.pool.sort.field
                this.sortDesc = this.$store.state.pool.sort.desc
            })
        },
        fetchData() {
            /*
            Fetching table data is handled by both the server and the client:

            The server performs filtering tasks, such as searching by pool name.
            The client handles pagination and sorting.

            This approach is used because the API pulls data from multiple sources.
            Paginating and sorting server-side would be costly without a result cache.
            Additionally, with our small dataset, client-side pagination is perfectly
            viable and allows for quicker page navigation.
            */

            // console.log('fetching pool data from API')

            let params = {
                limit: 1000, // fetch all data - pagination and sorting is client side
                offset: 0
            };

            let searchValue = this.$store.state.pool.filter.searchValue;
            if (searchValue) {
                params.searchValue = searchValue;
            }

            let status = this.$store.state.pool.filter.status;
            if (status) {
                params.status = status;
            }

            let exportRule = this.$store.state.pool.filter.exportRule;
            if (exportRule) {
                params.exportRule = exportRule;
            }

            let party = this.$store.state.party;
            if (party) {
                params.party = party;
            }

            /*if (true === showDetailedInfo) {
              params.showDetailedInfo = true;
              this.details = true;
            } else {
              this.details = false;
            }*/
            this.details = true;
            params.showDetailedInfo = true;

            this.$http
                .get("interface/pool", {
                    params: params,
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/interface/pool"
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    // console.log(data);
                    this.updateTable(data);
                });
        },
        onPageChanged(page) {
            this.currentPage =  page
            this.$store.commit('pool/setCurrentPage', this.currentPage);
        },
        onSortChanged({ sortBy, sortDesc }) {
          this.$store.commit('pool/setSort', {
            field: sortBy,
            desc: sortDesc,
          })
        },
        onSearch() {
          this.fetchData();
          this.onPageChanged(1)
        },
        getTitleByStatus(status) {
            let title;
            switch (status) {
                case 1:
                    title = 'online';
                    break;
                case 2:
                    title = 'abgelaufen';
                    break;
                case 3:
                    title = 'deaktiviert';
                    break;
                case 4:
                    title = 'gelöscht';
                    break;
                default:
                    title = 'inaktiv';
            }

            return title;
        },
        getVariantByStatus(status) {
            let variant;
            switch (status) {
                case 1:
                    variant = 'success';
                    break;
                case 2:
                    variant = 'warning';
                    break;
                case 3:
                    variant = 'dark';
                    break;
                case 4:
                    variant = 'danger';
                    break;
                default:
                    variant = 'secondary';
            }

            return variant;
        }
    },
    computed: {
        poolCount() {
            return this.pools.length;
        },
    }
};
</script>
<style>
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>
