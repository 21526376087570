import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import VueResource from 'vue-resource'
import jwt_decode from "jwt-decode";

import { fetchAdfUser } from '../helpers/api';
import poolStore from './poolStore';
import poolProxyStore from './poolProxyStore';
import emailTemplateStore from './emailTemplateStore';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    key: 'adf-admin',
    storage: localStorage
});

export const store = new Vuex.Store({
    state: {
        party: '',
        authStatus: '',
        authError: '',
        token : localStorage.getItem('token') || '',
        bearerToken: localStorage.getItem('bearerToken') || '',
        user: {},
        userRole: '',
        exportFilterDateFrom: '',
        exportFilterDateTo: '',
        quotaFilterDateFrom: '',
        quotaFilterDateTo: '',
        poolStatsFilterDateFrom: '',
        poolStatsFilterDateTo: ''
    },
    actions: {
        login({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                this._vm.$http.post(this._vm.$authUrl + 'auth', user)
                .then(resp => {
                    if (resp.data.newPasswordRequired) {
                        commit('auth_success', {token: null, bearerToken: null, user: user})
                        resolve(resp.data);
                        return;
                    }

                    if (!resp.data.token) {
                        const err = resp.data.message ? resp.data.message : 'Authentication failed';
                        commit('auth_error', err)
                        reject(err)
                        return;
                    }

                    const bearerToken = resp.data.token;
                    const decodedToken = jwt_decode(bearerToken);
                    user.password = '';
                    user.userDisplayName = decodedToken['given_name'] + ' ' + decodedToken['family_name'];
                    user.userRole = decodedToken['custom:role'];
                    user.adfAdminUser = decodedToken['custom:adf_admin_user'];
                    user.adfAdminUserToken = decodedToken['custom:adf_admin_user_auth'];

                    const token = {value: bearerToken, exp: decodedToken['exp']};
                    localStorage.setItem('bearerToken', JSON.stringify(token));

                    fetchAdfUser(user.adfAdminUser, user, function(adfUser) {
                        user.party = adfUser.party;

                        commit('auth_success', {token, user})
                        resolve(resp)
                    });
                })
                .catch(err => {
                    commit('auth_error', err)
                    localStorage.removeItem('token')
                    reject(err)
                })
            })
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                commit('logout')
                //localStorage.removeItem('token')
                localStorage.removeItem('bearerToken')
                localStorage.removeItem('adf-admin');
                resolve()
            })
        },
        reset (state) {
            state.commit('setPartyFilter', '');
            state.commit('setLogExportsFilterDateFrom', '');
            state.commit('setLogExportsFilterDateTo', '');
            state.commit('setLogQuotaFilterDateFrom', '');
            state.commit('setLogQuotaFilterDateTo', '');
            state.commit('setLogPoolStatsFilterDateFrom', '');
            state.commit('setLogPoolStatsFilterDateTo', '');
        }
    },
    getters: {
        isLoggedIn: state => () => {
            if (!state.bearerToken) {
                return false;
            }

            const currentTs = Math.floor(Date.now() / 1000);
            if (currentTs > state.bearerToken.exp) {
                return false;
            }

            return true;
        },
        authStatus: state => state.authStatus,
        user: state => state.user ? state.user : null,
        userRole: state => state.userRole ? state.userRole : null,
        authError: state => state.authError ? state.authError : null
    },
    mutations: {
        auth_request(state) {
            state.authStatus = 'loading'
        },
        auth_success(state, data) {
            state.authStatus = 'success'
            state.bearerToken = data.token
            state.userRole = data.user.userRole
            state.user = data.user
            state.authError = ''
        },
        auth_error(state, err) {
            state.authStatus = 'error'
            state.authError = err;
        },
        logout(state) {
            state.authStatus = ''
            state.bearerToken = ''
            state.user = {}
            state.userRole = ''
            state.token = '';
        },

        setLogExportsFilterDateFrom (state, dateFrom) {
            state.exportFilterDateFrom = dateFrom;
        },
        setLogExportsFilterDateTo (state, dateTo) {
            state.exportFilterDateTo = dateTo;
        },
        setLogQuotaFilterDateFrom (state, dateFrom) {
            state.quotaFilterDateFrom = dateFrom;
        },
        setLogQuotaFilterDateTo (state, dateTo) {
            state.quotaFilterDateTo = dateTo;
        },
        setLogPoolStatsFilterDateFrom (state, dateFrom) {
            state.poolStatsFilterDateFrom = dateFrom;
        },
        setLogPoolStatsFilterDateTo (state, dateTo) {
            state.poolStatsFilterDateTo = dateTo;
        },

        setPartyFilter (state, party) {
            state.party = party;
        },
        setAuthError (state, authError) {
            state.authError = authError;
        }
    },
    modules: {
      pool: poolStore,
      poolProxy: poolProxyStore,
      emailTemplate: emailTemplateStore
    },
    plugins: [vuexLocal.plugin]
});
