<template>
  <div>
    <app-header></app-header>
    <div>
        <router-view></router-view>
      </div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";

export default {
  name: "app",
  components: {
    appHeader: Header,
  }
};
</script>

<style>
* {
  font-size: 13px;
}
table tr td {
  white-space: nowrap;
}
</style>
