<template>
    <div class="container">
        <div class="float-right">
            <b-button-close @click="$router.replace('/pools')">
                <h2 aria-hidden="true">&times;</h2>
            </b-button-close>
        </div>

        <div class="row align-items-center">
            <ul>
                <li>
                    <strong>Key:</strong>
                    {{ poolKey }}
                </li>
                <li>
                    <strong>Name:</strong>
                    {{ pool.name }}
                </li>
                <li>
                    <strong>Export-Regel:</strong>
                    {{ exportRuleTitle }}
                </li>
            </ul>
        </div>

        <div class="row ">
            <b-button size="sm" variant="secondary" @click="unlock">Exportsperre entfernen</b-button>
            <span class="align-middle m-1" v-if="showPoolUnlockedHint">Sperre entfernt!</span>
        </div>

        <div class="row mt-2">
            <v-jsoneditor v-model="pool" :plus="false" height="500px" @error="onError"
                          :options="{mode: 'code'}"></v-jsoneditor>
        </div>
        <div class="row my-3">
            <div class="col">
                <b-alert
                    variant="success"
                    :show="alertDismissCountDown"
                    fade
                    dismissible
                    @dismissed="alertDismissCountDown=0; saveButtonDisabled=false"
                    @dismiss-count-down="countDownChanged"
                >
                    Die Pool-Einstellungen wurden erfolgreich gespeichert.
                </b-alert>
                <b-alert
                    variant="danger"
                    :show="saveError"
                    fade
                    dismissible
                    @dismissed="saveButtonDisabled=false"
                >
                    Fehler beim Speichern:
                    <br/>
                    {{ exportScheduleErrorMessage }}
                    <p v-show="jsonErrors">{{ JSON.stringify(jsonErrors, undefined, 2) }}</p>
                </b-alert>
            </div>
        </div>
        <div class="row my-3">
            <b-button variant="primary" @click="save()" :disabled="saveButtonDisabled">Speichern</b-button>
        </div>
    </div>
</template>

<script>
import VJsoneditor from "v-jsoneditor";

export default {
    data() {
        return {
            poolKey: null,
            pool: {},
            schema: {},
            exportRuleTitle: '',
            alertDismissCountDown: 0,
            saveButtonDisabled: false,
            showPoolUnlockedHint: false,
            saveError: false,
            jsonErrors: null,
            exportScheduleErrorMessage: "",
        };
    },
    components: {
        VJsoneditor
    },
    mounted() {
        window.addEventListener("keydown", this.keyboardSave);
    },
    destroyed() {
        window.removeEventListener("keydown", this.keyboardSave);
    },
    created() {
        this.poolKey = this.$route.params.poolKey;
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$http
                .get("form/pool/update/" + this.poolKey, {
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/form/pool/update/" + this.poolKey
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    //this.schema = data.form;
                    this.pool = data.initialValues;
                    this.exportRuleTitle = data.exportRuleTitle;
                });
        },
        onError() {
            console.log("error");
        },
        keyboardSave(e) {
            if (
                (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
                e.keyCode == 83
            ) {
                e.preventDefault();
                this.save();
            }
        },
        save() {
            this.saveButtonDisabled = true;
            this.saveError = false;
            this.jsonErrors = null;
            this.exportScheduleErrorMessage = "";

            this.$http
                .put("pool/" + this.poolKey, this.pool, {
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-PUT-/addressflow/v1/pool/" + this.poolKey
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    if (data.success === true) {
                        this.alertDismissCountDown = 2;
                    } else {
                        this.saveError = true;
                        if (data.message) {
                            this.exportScheduleErrorMessage = data.message;
                        }
                        if (data.errors) {
                            this.jsonErrors = data.errors;
                        }
                    }
                });
        },
        unlock() {
            this.$http
                .put("pool/" + this.poolKey + "/unlock", null, {
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-PUT-/addressflow/v1/pool/" + this.poolKey + "/unlock"
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    if (data.success === true) {
                        this.showPoolUnlockedHint = true;
                    }
                });
        },
        countDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown;
        }
    }
};
</script>

