const initialFilter = {
  searchValue: '',
}

const poolProxyStore = {
  namespaced: true,
  state: () => ({
    currentPage: 1,
    filter: initialFilter
  }),
  mutations: {
    setCurrentPage (state, currentPage) {
      state.currentPage = currentPage
    },
    setFilter (state, filter) {
      state.filter = filter;
    },
  },
  actions: {
    reset(state) {
      state.commit('setFilter', initialFilter);
    }
  },
  getters: {
    currentPage: (state) => state.currentPage,
    filter: (state) => state.filter
  }
};

export default poolProxyStore;
