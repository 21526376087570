const initialFilter = {
    name: '',
    startDate: '',
    endDate: '',
}

const initialSort = {
    field: 'template_id',
    desc: true,
}

const emailTemplateStore = {
    namespaced: true,
    state: () => ({
        currentPage: 1,
        filter: initialFilter,
        sort: initialSort,
    }),
    mutations: {
        setCurrentPage(state, currentPage) {
            state.currentPage = currentPage
        },
        setFilter(state, filter) {
            state.filter = filter;
        },
        setSort(state, sort) {
            state.sort = sort;
        },
    },
    actions: {
        reset({ commit }) {
            commit('setFilter', initialFilter);
            commit('setSort', initialSort);
            commit('setCurrentPage', 1);
        }
    },
    getters: {
        currentPage: (state) => state.currentPage,
        filter: (state) => state.filter
    }
};

export default emailTemplateStore;
